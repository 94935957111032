import {defineStore} from 'pinia'
import {ref, computed} from 'vue'
import {useNotification} from '@/composables/useNotification'
import {useNuxtApp} from 'nuxt/app'
import {$lara, $larafetch} from '@/utils/$larafetch'

export const useConfigurator = defineStore('configurator', () => {
    const notification = useNotification()

    const configuration = ref({})
    const components = ref(null)
    const attributes = ref(null)
    const variants = ref(null)
    const configurationDataLoaded = ref(false)
    const configurationLoading = ref(false)
    const configurationSaving = ref(false)

    const prices = computed(() => {
        const prices = {}
        if (configuration.value) {
            for (const [componentName, attribute] of Object.entries(configuration.value)) {
                prices[componentName] = 0.00
                for (const [attributeName, variant] of Object.entries(attribute)) {
                    prices[componentName] += parseFloat(variant.price)
                }
            }
        }
        return prices
    })

    const oldSubtotal = ref(0)

    const subtotal = computed(() => {
        let newSubtotal = 0
        for (const price of Object.values(prices.value)) {
            newSubtotal += price
        }
        oldSubtotal.value = subtotal.value;
        return newSubtotal
    })

    async function fetchConfigurationData() {
        configurationDataLoaded.value = false
        await $larafetch(useNuxtApp().$apiRoute('spa.frontend.getConfigData')).then(response => {
                components.value = response.components
                attributes.value = response.attributes
                variants.value = response.variants
                configurationDataLoaded.value = true
            }).catch(error => {
                configurationDataLoaded.value = true
                notification.error('Konfiguratordaten konnten nicht geladen werden.')
            })
    }

    function initConfiguration() {
        // get first variant without parent variant
        const variantToStartWith = variants.value?.find((variant) => !variant.parent_variant_id )
        if (variantToStartWith) {
            const attribute = attributes.value.find((attribute) => attribute.id === variantToStartWith.attribute_id)
            const component = components.value.find((component) => component.id === attribute.component_id)

            setConfiguration(component, attribute, variantToStartWith)
        }
    }

    async function saveConfiguration(status='saved') {
        configurationSaving.value = true
        const selection = []
        let savedConfiguration
        if (configuration.value) {
            for (const [componentName, attribute] of Object.entries(configuration.value)) {
                for (const [attributeName, variant] of Object.entries(attribute)) {
                    // id needed for the database, system_name needed for the frontend to load the configuration
                    selection.push({id: variant.id, system_name: variant.system_name })
                }
            }
        }

        await $lara.post('/app/spa/frontend/configurations', {
            body: {
                selection: JSON.stringify(selection),
                status: status
            }
        }).then((response) => {
            configurationSaving.value = false
            savedConfiguration = response.data
        }).catch((error) => {
            notification.error('Konfiguration konnte nicht gespeichert werden')
        })

        return savedConfiguration
    }

    function printConfiguration(token) {
        $larafetch(useNuxtApp().$apiRoute('spa.frontend.configurations.print', token), {
            method: 'GET',
        }).then(response => {
            // prepare download
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response]))

            link.setAttribute('download', 'visolva_konfiguration.pdf')
            document.body.appendChild(link)
            link.click()
        }).catch((error) => {
            const toast = useNotification()
            const app = useNuxtApp()
            if (error && error.response && error.response.status === 422) {
                toast.error(app.$i18n.t('invalidRequestData'))
            } else if (error && error.response && error.response.status === 404) {
                toast.error(app.$i18n.t('modelDoesNotExist'))
            }
            throw error
        })
    }

    function loadConfiguration(token) {
        configurationLoading.value = true
        $larafetch(useNuxtApp().$apiRoute('spa.frontend.configurations.show', token)).then((response) => {
            const selections = JSON.parse(response.data.selection)
            Object.values(selections).forEach((selection) => {
                const variant = variants.value.find((variant) => variant.system_name === selection.system_name)
                if (variant) {
                    const attribute = attributes.value.find((attribute) => attribute.id === variant.attribute_id)
                    const component = components.value.find((component) => component.id === attribute.component_id)

                    setConfiguration(component, attribute, variant)

                }
            })
            configurationLoading.value = false
        }).catch((error) => {
            configurationLoading.value = false
            const toast = useNotification()
            const app = useNuxtApp()
            if (error && error.response && error.response.status === 422) {
                toast.error(app.$i18n.t('invalidRequestData'))
            } else if (error && error.response && error.response.status === 404) {
                toast.error(app.$i18n.t('modelDoesNotExist'))
            }
            throw error
        })
    }

    function setConfiguration(component, attribute, variant) {
        // make sure component and attribute are set inside configuration
        configuration.value[component.name] = configuration.value[component.name] || {};
        configuration.value[component.name][attribute.name] = configuration.value[component.name][attribute.name] || {};
        // save old selected variant
        const oldVariant = configuration.value[component.name][attribute.name]
        // set new selected Variant
        configuration.value[component.name][attribute.name] = variant

        if (oldVariant.id !== variant.id) {
            // update each attribute
            attributes.value.forEach((attribute) => {
                const component = components.value.find((component) => component.id === attribute.component_id)
                const availableVariants = variants.value.filter((variant) => {
                    // check if variant has a selected parent variant or no parent and is related to the attribute
                    if (variant.attribute_id === attribute.id) {
                        if (variant.parent_variant_id) {
                            // get parent variant
                            const parentVariant = variants.value.find((parentVariant) => parentVariant.id === variant.parent_variant_id)
                            const parentAttribute = attributes.value.find((parentAttribute) => parentAttribute.id === parentVariant.attribute_id)
                            const parentComponent = components.value.find((parentComponent) => parentComponent.id === parentAttribute.component_id)
                            // check if parent variant is selected
                            if (configuration.value[parentComponent.name] && configuration.value[parentComponent.name][parentAttribute.name]) {
                                if (configuration.value[parentComponent.name][parentAttribute.name].id === parentVariant.id) {
                                    return true
                                }
                            }
                        } else {
                            return true
                        }
                    }
                    return false
                })

                if (availableVariants.length > 0) {
                    // check if attribute has a selected variant
                    if (configuration.value[component.name] && configuration.value[component.name][attribute.name]) {
                        // check if selected variant is still available
                        const currentSelectedVariant = availableVariants.find((variant) => variant.id === configuration.value[component.name][attribute.name].id)
                        if (!currentSelectedVariant) {
                            // if not available set check if there is a new variant with the same name
                            if (availableVariants.find((variant) => variant.system_name.toLowerCase() === configuration.value[component.name][attribute.name].system_name.toLowerCase())) {
                                // if there is a variant with the same name, set it as selected
                                const newVariant = availableVariants.find((variant) => variant.system_name.toLowerCase() === configuration.value[component.name][attribute.name].system_name.toLowerCase())
                                setConfiguration(component, attribute, newVariant)
                            } else {
                                // if there is no variant with the same name, set first available variant as selected
                                setConfiguration(component, attribute, availableVariants[0])
                            }
                        }
                    } else {
                        // if there is no variant selected yet, set first available variant
                        configuration.value[component.name] = configuration.value[component.name] || {};
                        configuration.value[component.name][attribute.name] = configuration.value[component.name][attribute.name] || {};
                        setConfiguration(component, attribute, availableVariants[0])
                    }
                } else {
                    // if there are no variants available, remove attribute from configuration
                    delete configuration.value[component.name][attribute.name]
                }
            })
        }
    }

    return {
        configuration,
        components,
        attributes,
        variants,
        prices,
        subtotal,
        oldSubtotal,
        configurationDataLoaded,
        configurationSaving,
        configurationLoading,
        fetchConfigurationData,
        initConfiguration,
        saveConfiguration,
        printConfiguration,
        loadConfiguration,
        setConfiguration,
    }
})
